main {
  min-height: 80vh;
}

/* Modify navbar backgorund color */
.navbar-custom {
  background-color: #556357;
}

.center {
  text-align: center;
}

/* General */
a {
  color: #556357;
}
a:hover {
  color: black;
  text-decoration: none;
}
.link-like {
  color: #556357;
  cursor: pointer;
}
.link-like:hover {
  color: black;
  text-decoration: none;
}
.orange {
  color: darkorange;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
  padding: 10px;
  box-sizing: border-box;
}

.carousel-caption h5 {
  color: #fff;
}

.carousel img {
  height: 400px;
  padding-top: 100px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}

/* manual textarea */
.manual-textarea {
  overflow: scroll;
  resize: both;
  padding: 1rem;
  width: 100%;
  height: 200px;
  background: #f5f5f5;
  color: #808080;
  white-space: pre-wrap;
}
